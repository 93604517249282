import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";
import ChatListPage from "../pages/ChatListPage";
import ChatPage from "../pages/ChatPage";
import {socket} from "../socket/socket";
import LoadingPage from "../pages/LoadingPage";
import {AppBar, IconButton, Toolbar} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ChatProvider = () => {
    const [chats, setChats] = useState(null);

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const response = await axios.get("/chat/getAll");
                setChats(response.data);

                socket.connect();
                socket.on("messageUpdates", updateMessages);
            } catch (error) {
                console.error("Failed to load chats:", error);
            }
        };

        fetchChats();

        return () => {
            socket.disconnect();
            socket.off("messageUpdates", updateMessages);
        }
    }, []);

    const updateMessages = (messages) => {
        setChats((prevChats) => {
            // Create a copy of prevChats to modify
            const updatedChats = [...prevChats];

            messages.forEach((message) => {
                // Find the chat associated with the message's chatId
                const chatIndex = updatedChats.findIndex((chat) => chat._id === message.chatId);

                if (chatIndex === -1) return; // Skip if no chat is found

                const chatToUpdate = { ...updatedChats[chatIndex] }; // Clone the chat to update
                const messageIndex = chatToUpdate.messages.findIndex((msg) => msg._id === message._id);

                if (messageIndex === -1) {
                    // If message is new, add it to messages array
                    chatToUpdate.messages.push(message);
                } else {
                    // If message exists, replace it with the new message
                    chatToUpdate.messages[messageIndex] = message;
                }

                // Sort messages by timestamp
                chatToUpdate.messages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                // Update the chat in the updatedChats array
                updatedChats[chatIndex] = chatToUpdate;
            });

            return updatedChats;
        });
    };


    if (!chats) return <>
        <AppBar position="sticky">
            <Toolbar>
                <IconButton color="inherit" onClick={() => navigate("/")}>
                    <ArrowBackIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <LoadingPage/>
    </>

    return (
        <Routes>
            <Route path="/" element={<ChatListPage chats={chats} />} />
            <Route path=":chatId" element={<ChatPage chats={chats} updateMessages={updateMessages} />} />
        </Routes>
    );
};

export default ChatProvider;
