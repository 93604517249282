import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Typography,
    TextField,
    Button,
    AppBar,
    Toolbar,
    IconButton,
    Container,
    InputAdornment,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingPage from './LoadingPage';

const CustomerPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [customer, setCustomer] = useState();
    const [error, setError] = useState(null);

    // Fetch existing customer if not a new entry
    useEffect(() => {
        if (id!=='new') {
            const fetchCustomer = async () => {
                try {
                    const response = await axios.get(`/customer/getById/${id}`);
                    setCustomer(response.data);
                } catch (error) {
                    console.error('Error fetching customer details:', error);
                }
            };
            fetchCustomer();
        } else setCustomer({});
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomer((prevCustomer) => ({
            ...prevCustomer,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            if (id==='new') {
                // Create new customer
                await axios.post('/customer/create', customer);
            } else {
                // Update existing customer
                await axios.put(`/customer/update/${id}`, customer);
            }
            navigate('/customer'); // Redirect after saving
        } catch (error) {
            setError('Failed to save changes.');
        }
    };

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate('/customer')}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: '10px' }}>
                        {id==='new' ? 'Create Customer' : customer?.name || 'Edit Customer'}
                    </Typography>
                </Toolbar>
            </AppBar>
            {customer ? (
                <Container sx={{ py: '2em' }}>
                    <Typography variant="h5" gutterBottom>
                        {id==='new' ? 'Create New Customer' : 'Edit Customer Information'}
                    </Typography>
                    <TextField
                        label="Name"
                        name="name"
                        value={customer.name || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Phone Number"
                        name="phoneNumber"
                        value={customer.phoneNumber || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">+</InputAdornment>,
                            },
                        }}
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={customer.email || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Additional Information"
                        name="information"
                        value={customer.information || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        multiline
                        maxRows={20}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        sx={{ marginTop: 2 }}
                    >
                        Save Changes
                    </Button>
                    {error && <Typography color="error" sx={{ marginTop: 2 }}>{error}</Typography>}
                </Container>
            ) : (
                <LoadingPage />
            )}
        </>
    );
};

export default CustomerPage;
