import React from "react";
import {Divider, ListItem, ListItemText, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {messageStatusIcon} from "./MessageBubble";
import {truncate} from "../utils";


const ChatCard = ({chat}) => {
    const navigate = useNavigate();

    function selectCard() {
        navigate(`/chat/${chat._id}`);
    }

    return (
        <>
            <ListItem onClick={selectCard} sx={{cursor: "pointer"}}> <ListItemText primary={chat.name} secondary={
                <Typography component="span" variant="body2" color="textSecondary" display="flex" justifyContent="space-between" alignItems="center">
                            <span style={{
                                display: "flex",
                                alignItems: "center", // Align icon and text vertically
                                maxWidth: "70%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}>
        {chat.messages.length > 0 ? (
            <>
                {chat.messages[chat.messages.length - 1].senderType === "assistant" && (
                    <span style={{marginRight: "0.3em"}}>
                            {messageStatusIcon[chat.messages[chat.messages.length - 1].status]}
                        </span>
                )} {truncate(chat.messages[chat.messages.length - 1].content, 30)}
            </>
        ) : (
            "No messages yet"
        )}
    </span> <Typography component="span" variant="caption" color="textSecondary">
                    {chat.messages.length > 0 ? new Date(chat.messages[chat.messages.length - 1].timestamp).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    }) : ""}
                </Typography> </Typography>
            }/> </ListItem> <Divider/>
        </>
    );
};

export default ChatCard;
