import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    Typography,
    Box,
    AppBar,
    Toolbar,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemIcon, Container
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ChatIcon from "@mui/icons-material/Chat";
import GavelIcon from '@mui/icons-material/Gavel';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { AuthContext } from "../provider/AuthProvider";

const Dashboard = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <>
            {/* App Bar with Logout Button */}
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        silverr AI
                    </Typography>
                    <IconButton color="inherit" onClick={logout}>
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Container
                sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2
                }}
            >
                <List>
                    <ListItem onClick={() => navigate("/chat")} sx={{cursor: "pointer"}}>
                        <ListItemIcon>
                            <ChatIcon />
                        </ListItemIcon>
                        <ListItemText primary="Chats" />
                    </ListItem>
                    <ListItem onClick={() => navigate("/customer")} sx={{cursor: "pointer"}}>
                        <ListItemIcon>
                            <SupervisedUserCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Customers" />
                    </ListItem>
                    <ListItem onClick={() => navigate("/lawfield")} sx={{cursor: "pointer"}}>
                        <ListItemIcon>
                            <GavelIcon />
                        </ListItemIcon>
                        <ListItemText primary="Knowledge Base" />
                    </ListItem>
                </List>
            </Container>
        </>
    );
};

export default Dashboard;
