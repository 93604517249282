import {List, AppBar, Toolbar, IconButton, Typography, Box, Container} from "@mui/material";
import ChatCard from "../components/ChatCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingPage from "./LoadingPage";
import {useNavigate} from "react-router-dom";
import React from "react";

const ChatListPage = ({chats}) => {
    const navigate = useNavigate();

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        Chats
                    </Typography>
                </Toolbar>
            </AppBar>
            {
                chats ?
                    <Container>
                        <List>
                            {chats.map((chat) => (
                                <ChatCard
                                    key={chat._id}
                                    chat={chat}
                                />
                            ))}
                        </List>
                    </Container>
                    : <LoadingPage/>
            }
        </>
    );
};

export default ChatListPage;
