import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    Card,
    CardContent,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Container, Button
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingPage from "./LoadingPage";

const CustomerListPage = () => {
    const navigate = useNavigate();
    const [customers, setCustomers] = useState(null);

    // Fetch customer data from the backend
    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get("/customer/getAll"); // Adjust endpoint as needed
                setCustomers(response.data);
            } catch (error) {
                console.error("Error fetching customers:", error);
            }
        };
        fetchCustomers();
    }, []);

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        Customers
                    </Typography>
                    <Button color="inherit" onClick={() => navigate("/customer/new")} sx={{ marginLeft: "auto" }}>
                        Create New
                    </Button>
                </Toolbar>
            </AppBar>

            {customers ? (
                <Container sx={{py: "2em"}}>
                    <List sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {customers.map((customer) => (
                            <ListItem key={customer._id} disableGutters sx={{cursor: "pointer"}}>
                                <Card
                                    sx={{ width: '100%', cursor: 'pointer' }}
                                    onClick={() => navigate(`/customer/${customer._id}`)}
                                >
                                    <CardContent>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="h6">{customer.name}</Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{mt: 'auto'}}>
                                                {customer.phoneNumber}
                                            </Typography>
                                        </Box>
                                        <Typography variant="body2" color="textSecondary">
                                            {customer.email}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                                            {customer.information || "No additional information available."}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </ListItem>
                        ))}
                    </List>
                </Container>
            ) : (
                <LoadingPage />
            )}
        </>
    );
};

export default CustomerListPage;