import React, { useEffect, useState } from "react";
import {AppBar, Toolbar, IconButton, Typography, TextField, Button, Box, Container} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";

const LawRulePage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    // Extract lawTopic from the query parameters in URL
    const queryParams = new URLSearchParams(useLocation().search);
    const lawTopic = queryParams.get("lawTopic");
    const lawField = queryParams.get("lawField");

    // Fetch existing LawRule if `id` is provided
    useEffect(() => {
        if (id !== "new") {
            const fetchLawRule = async () => {
                try {
                    const response = await axios.get(`/knowledge/lawrule/getById/${id}`);
                    setTitle(response.data.title);
                    setContent(response.data.content);
                } catch (error) {
                    console.error("Error fetching law rule:", error);
                }
            };
            fetchLawRule();
        }
    }, [id]);

    // Handle form submission to create or update LawRule
    const handleSubmit = async () => {
        try {
            const lawRuleData = { title, content, lawTopic };
            if (id !== "new") {
                // Update existing LawRule
                await axios.put(`/knowledge/lawrule/update/${id}`, lawRuleData);
            } else {
                // Create new LawRule
                await axios.post("/knowledge/lawrule/create", lawRuleData);
            }
            navigate(`/lawrule?lawTopic=${lawTopic}&lawField=${lawField}`);  // Redirect to list page with lawTopic
        } catch (error) {
            console.error("Error saving law rule:", error);
        }
    };

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate(`/lawrule?lawTopic=${lawTopic}&lawField=${lawField}`)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        {id ? "Edit Law Rule" : "Create Law Rule"}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container sx={{py: "1em"}}>
                <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Content"
                    variant="outlined"
                    fullWidth
                    multiline
                    maxRows={20}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    margin="normal"
                />
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Container>
        </>
    );
};

export default LawRulePage;
