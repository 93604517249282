import React from "react";
import { CircularProgress, Typography, Box } from "@mui/material";

const LoadingPage = () => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="80vh"
            flexDirection="column"
            bgcolor="#f5f5f5"
        >
            <CircularProgress color="primary" size={60} />
            <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
                Loading, please wait...
            </Typography>
        </Box>
    );
};

export default LoadingPage;
