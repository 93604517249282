import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    Card,
    CardContent,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Button,
    Stack,
    Container
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import LoadingPage from "./LoadingPage";

const LawTopicListPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [lawTopics, setLawTopics] = useState(null);

    // Extract lawField from URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const lawField = queryParams.get("lawField");

    // Fetch law topic data from the backend, filtered by lawField
    useEffect(() => {
        const fetchLawTopics = async () => {
            try {
                const response = await axios.get(`/knowledge/lawtopic/getAll`, {
                    params: { lawField }  // Pass lawField as a query parameter
                });
                setLawTopics(response.data);
            } catch (error) {
                console.error("Error fetching law topics:", error);
            }
        };

        // Only fetch if lawField is provided
        if (lawField) {
            fetchLawTopics();
        }
    }, [lawField]);

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate("/lawfield")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        Law Topics
                    </Typography>
                    <Button
                        color="inherit"
                        onClick={() => navigate(`/lawtopic/new?lawField=${lawField}`)}
                        sx={{ marginLeft: "auto" }}
                    >
                        Create New
                    </Button>
                </Toolbar>
            </AppBar>

            {lawTopics ? (
                <Container sx={{py: "1em"}}>
                    <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {lawTopics.map((topic) => (
                            <ListItem key={topic._id} disableGutters>
                                <Card sx={{ width: '100%' }}>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                                            <Typography variant="h6">{topic.title}</Typography>
                                            <Stack direction="row" spacing={1}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => navigate(`/lawtopic/${topic._id}?lawField=${lawField}`)}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => navigate(`/lawrule?lawField=${lawField}&lawTopic=${topic._id}`)}
                                                >
                                                    View
                                                </Button>
                                            </Stack>
                                        </Box>
                                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                                            {topic.content || "No additional information available."}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </ListItem>
                        ))}
                    </List>
                </Container>
            ) : (
                <LoadingPage />
            )}
        </>
    );
};

export default LawTopicListPage;
