import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    Card,
    CardContent,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Button,
    Stack,
    Container
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingPage from "./LoadingPage";

const LawFieldListPage = () => {
    const navigate = useNavigate();
    const [lawFields, setLawFields] = useState(null);

    // Fetch law field data from the backend
    useEffect(() => {
        const fetchLawFields = async () => {
            try {
                const response = await axios.get("/knowledge/lawfield/getAll");
                setLawFields(response.data);
            } catch (error) {
                console.error("Error fetching law fields:", error);
            }
        };
        fetchLawFields();
    }, []);

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        Law Fields
                    </Typography>
                    <Button color="inherit" onClick={() => navigate("/lawfield/new")} sx={{ marginLeft: "auto" }}>
                        Create New
                    </Button>
                </Toolbar>
            </AppBar>

            {lawFields ? (
                <Container sx={{py: "1em"}}>
                    <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {lawFields.map((field) => (
                            <ListItem key={field._id} disableGutters>
                                <Card sx={{ width: '100%' }}>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                                            <Typography variant="h6">{field.title}</Typography>
                                            <Stack direction="row" spacing={1}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => navigate(`/lawfield/${field._id}`)}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => navigate(`/lawtopic?lawField=${field._id}`)}
                                                >
                                                    View
                                                </Button>
                                            </Stack>
                                        </Box>
                                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                                            {field.content || "No additional information available."}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </ListItem>
                        ))}
                    </List>
                </Container>
            ) : (
                <LoadingPage />
            )}
        </>
    );
};

export default LawFieldListPage;
