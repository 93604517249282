import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthProvider from "./provider/AuthProvider";
import ChatProvider from "./provider/ChatProvider";
import Dashboard from "./pages/Dashboard";
import CustomerListPage from "./pages/CustomerListPage";
import CustomerPage from "./pages/CustomerPage";
import LawFieldListPage from "./pages/LawFieldListPage";
import LawFieldPage from "./pages/LawFieldPage";
import LawTopicListPage from "./pages/LawTopicListPage";
import LawTopicPage from "./pages/LawTopicPage";
import LawRuleListPage from "./pages/LawRuleListPage";
import LawRulePage from "./pages/LawRulePage";

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/chat/*" element={<ChatProvider />} />
                    <Route path="/customer" element={<CustomerListPage />} />
                    <Route path="/customer/:id" element={<CustomerPage />} />
                    <Route path="/lawfield" element={<LawFieldListPage />} />
                    <Route path="/lawfield/:id" element={<LawFieldPage />} />
                    <Route path="/lawtopic" element={<LawTopicListPage />} />
                    <Route path="/lawtopic/:id" element={<LawTopicPage />} />
                    <Route path="/lawrule" element={<LawRuleListPage />} />
                    <Route path="/lawrule/:id" element={<LawRulePage />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
