import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import LoginPage from "../pages/LoginPage";
import LoadingPage from "../pages/LoadingPage";

// Create AuthContext to provide authentication state
export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Function to check for token and authenticate user
    useEffect(() => {
        const authenticateUser = async () => {
            const token = localStorage.getItem("jwtToken");
            if (token) {
                try {
                    // Extract user ID from token (assuming JWT structure)
                    const userId = JSON.parse(atob(token.split(".")[1])).userId;

                    // Fetch user data
                    const response = await axios.get(`/user/getById/${userId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                    setUser(response.data);
                } catch (error) {
                    localStorage.removeItem("jwtToken"); // Clear invalid token
                    setUser(null);
                }
            }
            setLoading(false);
        };

        authenticateUser();
    }, []);

    // Function to handle login
    const onSuccessfulLogin = (user, token) => {
        localStorage.setItem("jwtToken", token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setUser(user);
    };

    const logout = () => {
        localStorage.removeItem("jwtToken");
        delete axios.defaults.headers.common["Authorization"];
        setUser(null);
    };

    // If loading, show loading text
    if (loading) return <LoadingPage/>;

    // If user is not set, show login form
    if (!user) {
        return <LoginPage onLoginSuccessful={onSuccessfulLogin} />;
    }

    // If user is authenticated, provide user context and render children
    return (
        <AuthContext.Provider value={{ user, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
