import React, { useEffect, useState } from "react";
import {AppBar, Toolbar, IconButton, Typography, TextField, Button, Box, Container} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";

const LawTopicPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    // Extract lawField from the query parameters in URL
    const queryParams = new URLSearchParams(useLocation().search);
    const lawField = queryParams.get("lawField");

    // Fetch existing LawTopic if `id` is provided
    useEffect(() => {
        if (id !== "new") {
            const fetchLawTopic = async () => {
                try {
                    const response = await axios.get(`/knowledge/lawtopic/getById/${id}`);
                    setTitle(response.data.title);
                    setContent(response.data.content);
                } catch (error) {
                    console.error("Error fetching law topic:", error);
                }
            };
            fetchLawTopic();
        }
    }, [id]);

    // Handle form submission to create or update LawTopic
    const handleSubmit = async () => {
        try {
            const lawTopicData = { title, content, lawField };
            if (id !== "new") {
                // Update existing LawTopic
                await axios.put(`/knowledge/lawtopic/update/${id}`, lawTopicData);
            } else {
                // Create new LawTopic
                await axios.post("/knowledge/lawtopic/create", lawTopicData);
            }
            navigate(`/lawtopic?lawField=${lawField}`);  // Redirect back to list with the filtered lawField
        } catch (error) {
            console.error("Error saving law topic:", error);
        }
    };

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate(`/lawtopic?lawField=${lawField}`)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        {id ? "Edit Law Topic" : "Create Law Topic"}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container sx={{py: "1em"}}>
                <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Content"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    margin="normal"
                />
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Container>
        </>
    );
};

export default LawTopicPage;
